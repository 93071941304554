import React from 'react'

function Navigation({previous,next,previousNav,nextNav}) {
  return (
    <div className=''>
      {previous &&<p className='mb-3'>
        <a href={previousNav} className='truncate hover:bg-[#0060a9] hover:border-[#0060a9] hover:underline rounded-[5px] text-white bg-[#0078d4] border border-[#0078d4] button'>{previous}</a>
      </p>}
      {next&&<p className=''>
        <a href={nextNav} className='truncate hover:bg-[#0060a9] hover:border-[#0060a9] hover:underline rounded-[5px] text-white bg-[#0078d4] border border-[#0078d4] button'>{next}</a>
      </p>}
    </div>
  )
}

export default Navigation
