import React from 'react';
import styles from './TermsAndConditions.module.css';

const TermsAndConditions = ({ onClose }) => {
  return (
    <div className={styles.modalBackground}>
      <div className={styles.modalContainer}>
        <button className={styles.closeButton} onClick={onClose}>&times;</button>
        <h1 className={styles.modalTitle}>Terms and Conditions</h1>
        <p className='text-justify'>Welcome to Vipas.AI. By accessing or using our platform, you agree to comply with and be bound by the following terms and conditions. Please read them carefully.
        </p>
        <section className={styles.termsSection}>
          <h2>1. Acceptance of Terms</h2>
          <p className='text-justify ml-4'>
          By using Vipas.AI, you agree to these Terms of Service, all applicable laws and regulations, and our Privacy Policy. If you do not agree, do not use our platform.
          </p>
        </section>

        <section className={styles.termsSection}>
          <h2>2. Services Provided</h2>
          <p className='text-justify ml-4'>
          Vipas.AI offers a platform where developers and data scientists can upload, share, and monetize their models and apps. Users can access these models and apps at affordable rates.
          </p>
        </section>

        <section className={styles.termsSection}>
          <h2>3. User Accounts
          </h2>
          <ul className='ml-4'>
            <li className='text-justify'><b>Account Creation:</b> To use certain features of our platform, you must create an account. You agree to provide accurate and complete information during the registration process and update your information as necessary to keep it accurate and complete. </li>
            <li className='text-justify'><b>Account Security:</b> You are responsible for maintaining the confidentiality of your account credentials and are fully responsible for all activities that occur under your account. You agree to notify Vipas.AI immediately of any unauthorized use of your account or any other breach of security.
            </li>
            <li className='text-justify'><b>Account Termination:</b>  Vipas.AI reserves the right to suspend or terminate your account at our discretion, with or without notice, for any reason, including, but not limited to, breach of these Terms of Service.
            </li>
          </ul>
        </section>

        <section className={styles.termsSection}>
          <h2>4. User Conduct
          </h2>
          <ul className='ml-4'>
          <li className='text-justify'><b>Lawful Use:</b> You agree to use Vipas.AI only for lawful purposes. You must not upload, post, or otherwise transmit through the platform any content that is unlawful, harmful, threatening, abusive, harassing, defamatory, vulgar, obscene, libelous, invasive of another's privacy, hateful, or racially, ethnically, or otherwise objectionable.</li>
            <li className='text-justify'><b>Content Responsibility:</b> You are solely responsible for the content you upload, and for any consequences arising from it. By uploading content, you affirm that you have the necessary rights to grant Vipas.AI the license to use, distribute, and display your content.
            </li>
            </ul>
        </section>

        <section className={styles.termsSection}>
          <h2>5. Content Ownership
          </h2>
          <ul className='ml-4'>
          <li className='text-justify'><b>Your Content: </b>  You retain ownership of the content you upload to Vipas.AI. By uploading content, you grant Vipas.AI a worldwide, non-exclusive, royalty-free license to use, distribute, and display your content in connection with the operation of the platform and the provision of services to you and to other users.
          </li>
            <li className='text-justify'><b>Intellectual Property Rights:</b>  All rights, title, and interest in and to the platform and its services, including, but not limited to, intellectual property rights, remain with Vipas.AI and its licensors. You agree not to reproduce, duplicate, copy, sell, resell, or exploit any portion of the platform without our express written permission.
            </li>
            </ul>
        </section>

        <section className={styles.termsSection}>
          <h2>6. Payment Terms
          </h2>
          <ul className='ml-4'>
          <li className='text-justify'><b>Commission: </b> Vipas.AI charges a commission on earnings generated through the platform. The specific commission rates and payment terms are detailed in the developer agreement.
          </li>
            <li className='text-justify'><b>Payment Processing:</b>  Payments to developers and data scientists will be processed according to the agreed schedule. Users must pay for access to models and apps using the provided payment methods.
            </li>
            <li className='text-justify'><b>Refund Policy:</b>  All transactions are final. However, if you encounter any issues with a transaction, please contact Vipas.AI support for assistance.

            </li>
            </ul>
        </section>

        <section className={styles.termsSection}>
          <h2>7. Privacy</h2>
          <ul className='ml-4'>
          <li className='text-justify'><b>Data Collection: </b> Your use of Vipas.AI is subject to our Privacy Policy, which describes how we collect, use, and share your personal information.
          </li>
            <li className='text-justify'><b>User Data:</b> We collect and use personal information as described in the Privacy Policy. By using Vipas.AI, you consent to such processing and you warrant that all data provided by you is accurate.
            </li>
            </ul>
        </section>

        <section className={styles.termsSection}>
          <h2>8. Security</h2>
          <ul className='ml-4'>
          <li className='text-justify'><b>Data Protection: </b>We implement industry-standard security measures to protect your data. However, we cannot guarantee absolute security of your information.
          </li>
            <li className='text-justify'><b>User Responsibility: </b> You are responsible for safeguarding your own password and should not disclose it to any third party.
            </li>
            </ul>
        </section>

        <section className={styles.termsSection}>
          <h2>9. Termination</h2>
          <ul className='ml-4'>
          <li className='text-justify'><b>Termination Rights: </b>Vipas.AI reserves the right to suspend or terminate your account at our discretion, with or without notice, for any violation of these terms, or for any other reason deemed appropriate by Vipas.AI.
          </li>
            <li className='text-justify'><b>Effect of Termination: </b> Upon termination, your right to use the platform will immediately cease. All provisions of these terms that by their nature should survive termination shall survive, including, without limitation, ownership provisions, warranty disclaimers, indemnity, and limitations of liability.
            </li>
            </ul>
        </section>

        <section className={styles.termsSection}>
          <h2>10. Limitation of Liability
          </h2>
          <ul className='ml-4'>
          <li className='text-justify'><b>No Liability: </b>Vipas.AI shall not be liable for any direct, indirect, incidental, special, consequential, or punitive damages arising out of or related to your use of the platform, even if Vipas.AI has been advised of the possibility of such damages.
          </li>
            <li className='text-justify'><b>Maximum Liability: </b> In no event shall Vipas.AI’s total liability to you for all damages, losses, and causes of action exceed the amount paid by you, if any, for accessing the platform in the last six months.
            </li>
            </ul>
        </section>

        <section className={styles.termsSection}>
          <h2>11. Indemnification</h2>
          <p className='text-justify ml-4'>
          You agree to indemnify, defend, and hold harmless Vipas.AI, its officers, directors, employees, and agents from and against any and all claims, damages, obligations, losses, liabilities, costs, and expenses (including but not limited to attorney's fees) arising from: (i) your use of and access to the platform; (ii) your violation of any term of these Terms of Service; (iii) your violation of any third-party right, including without limitation any intellectual property, property, or privacy right; or (iv) any claim that your content caused damage to a third party. This defense and indemnification obligation will survive these Terms of Service and your use of the platform.
          </p>
        </section>

        <section className={styles.termsSection}>
          <h2>12. Changes to Terms
          </h2>
          <ul className='ml-4'>
          <li className='text-justify'><b>Modification: </b>Vipas.AI reserves the right to modify these Terms of Service at any time. You will be notified of any changes through our platform.
          </li>
            <li className='text-justify'><b>Acceptance of Modifications: </b> Continued use of Vipas.AI after any such changes constitutes your acceptance of the new Terms of Service. It is your responsibility to review these Terms periodically for changes.
            </li>
            </ul>
        </section>
        <section className={styles.termsSection}>
          <h2>13. Governing Law
          </h2>
          <p className='text-justify ml-4'>
          These Terms of Service shall be governed and construed in accordance with the laws of [Your Jurisdiction], without regard to its conflict of law principles. You agree to submit to the personal jurisdiction of the courts located in [Your Jurisdiction] for the purpose of litigating all such claims or disputes.
          </p>
        </section>
        <section className={styles.termsSection}>
          <h2>14. Contact Information</h2>
          <p className='text-justify ml-4'>
          If you have any questions about these Terms of Service, please contact us at support@vipas.ai          </p>
        </section>
        <section className={styles.termsSection}>
          <h2>15. Miscellaneous
          </h2>
          <ul className='ml-4'>
          <li className='text-justify'><b>Entire Agreement: </b> These Terms of Service, along with the Privacy Policy and any other legal notices published by Vipas.AI, constitute the entire agreement between you and Vipas.AI concerning the platform.
          </li>
            <li className='text-justify'><b>Severability: </b>  If any provision of these Terms is deemed invalid by a court of competent jurisdiction, the invalidity of such provision shall not affect the validity of the remaining provisions of these Terms, which shall remain in full force and effect.
            </li>
            <li className='text-justify'><b>Waiver: </b>  No waiver of any term of these Terms shall be deemed a further or continuing waiver of such term or any other term, and Vipas.AI's failure to assert any right or provision under these Terms shall not constitute a waiver of such right or provision.
            </li>
            </ul>
        </section>
        <section className={styles.termsSection}>
          <h2>16. Third-Party Links
          </h2>
          <p className='text-justify ml-4'>
          The platform may contain links to third-party websites or services that are not owned or controlled by Vipas.AI. Vipas.AI has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third-party websites or services. You further acknowledge and agree that Vipas.AI shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods, or services available on or through any such websites or services.
By using Vipas.AI, you acknowledge that you have read, understood, and agree to be bound by these Terms of Service.
</p>
        </section>

      </div>
    </div>
  );
};

export default TermsAndConditions;
