import React from 'react'
import notFoundImg from '../assets/images/404.png'
function ErrorPage() {
  return (
    <div className='flex items-center 2xl:max-w-[1920px] mx-auto h-full mt-6 justify-center'>
      <div className='w-[40%] shrink-0'>
        <img src={notFoundImg} className='w-full object-contain h-auto' alt="not-found"/>
      </div>
     </div>
  )
}

export default ErrorPage
