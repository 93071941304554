import React from "react";
import Navigation from '../Navigation';
import { CopyBlock, anOldHope } from 'react-code-blocks';
import SEOHelmet from '../../utils/SEOHelmet';

const AppBaseimage1 = () => {
    const codeSnippet=`docker pull vipasai/vps-streamlit-base:1.0
docker run -p 8501:8501 -v $(pwd):/app -w /app vipasai/vps-streamlit-base:1.0 streamlit run app.py`
    return (
        <><SEOHelmet title={"VPS Streamlit Base: 1.0"} description={"Explore the Streamlit base image provided by Vipas.AI for creating apps, including its features, usage, and benefits."} canonicalUrl={"https://docs.vipas.ai/developer-docs/vps-streamlit-base:1.0"}/>
        <div className="flex my-8 relative">
            <div className="w-[100%] xl:w-[66.66%] px-[24px]">
                    <h1 className="heading">vps-streamlit-base:1.0</h1>
                    <div className="my-6" id="overview">
                        <h6 className="sub-heading" >Overview</h6>
                        <p className="my-2">
                            The <strong>Base Image</strong> serves as the foundational layer for app development and deployment. The base image <code>vps-streamlit-base:1.0</code> is meticulously crafted to include a comprehensive set of pre-installed dependencies, ensuring a robust and versatile environment for developing and deploying data science and machine learning applications.
                            <br />
                            For more details and to download the available base images, please visit our Docker Hub repository. You can find the <strong>vps-streamlit-base</strong> image and its various tags by following this link&nbsp;
                            <a href="https://hub.docker.com/r/vipasai/vps-streamlit-base/tags" target="_blank" rel="noopener noreferrer" className='links !text-[16px]'>Open Docker Hub</a>.
                        </p>
                        <p className="my-2">To test the app in the vipas streamlit environment you can use the following commands.
</p>
<div className='w-full my-4'>
              <CopyBlock
                text={codeSnippet}
                language="shell"
                showLineNumbers={true}
                theme={anOldHope}
                wrapLines={true}
                codeBlock
              />
            </div>
                    </div>
                    <div className="my-6" id="pre-installed-dependencies">
                        <h6 className="sub-heading">Pre-installed Dependencies</h6>
                        <p className="my-2">The base image includes a wide range of libraries and frameworks pre-installed, which are essential for various data science and machine learning tasks:</p>
                        <ul>
                            <li className="link-list"><code>streamlit==1.37.0</code>: A powerful framework for creating interactive web applications.</li>
                            <li className="link-list"><code>streamlit-drawable-canvas==0.9.3</code>: An add-on for Streamlit that allows users to draw and annotate images or create simple sketches directly within Streamlit applications.</li>
                            <li className="link-list"><code>vipas==1.0.2</code>: Core package for Vipas.AI functionalities, providing essential tools and utilities.</li>
                            <li className="link-list"><strong>Data manipulation and analysis:</strong></li>
                            <ul>
                                <li className="link-list"><code>pandas==2.2.2</code>: A versatile library for data manipulation and analysis, offering data structures and operations for manipulating numerical tables and time series.</li>
                                <li className="link-list"><code>numpy==1.26.4</code>: A fundamental package for scientific computing with Python, providing support for arrays, matrices, and a large library of mathematical functions.</li>
                            </ul>
                            <li className="link-list"><strong>Scientific computing:</strong></li>
                            <ul>
                                <li className="link-list"><code>scipy==1.13.1</code>: A library used for scientific and technical computing, extending the capabilities of NumPy with additional modules for optimization, integration, interpolation, eigenvalue problems, and other tasks.</li>
                            </ul>
                            <li className="link-list"><strong>Data visualization:</strong></li>
                            <ul>
                                <li> className="link-list"<code>matplotlib==3.9.0</code>: A comprehensive library for creating static, animated, and interactive visualizations in Python.</li>
                                <li className="link-list"><code>seaborn==0.13.2</code>: A statistical data visualization library based on matplotlib, providing a high-level interface for drawing attractive and informative statistical graphics.</li>
                                <li className="link-list"><code>plotly==5.22.0</code>: An open-source graphing library that makes interactive, publication-quality graphs online.</li>
                            </ul>
                            <li className="link-list"><strong>Machine learning:</strong></li>
                            <ul>
                                <li className="link-list"><code>scikit-learn==1.5.0</code>: A machine learning library that features various classification, regression, and clustering algorithms, including support vector machines, random forests, gradient boosting, k-means, and DBSCAN.</li>
                            </ul>
                            <li className="link-list"><strong>Deep learning frameworks:</strong></li>
                            <ul>
                                <li className="link-list"><code>tensorflow==2.16.1</code>: An end-to-end open-source platform for machine learning, offering a comprehensive, flexible ecosystem of tools, libraries, and community resources.</li>
                                <li className="link-list"><code>keras==3.3.3</code>: A high-level neural networks API, capable of running on top of TensorFlow and designed to enable fast experimentation with deep neural networks.</li>
                                <li className="link-list"><code>keras-cv==0.9.0</code>: A library of Keras components designed for computer vision tasks, extending the capabilities of Keras for deep learning models.</li>
                                <li className="link-list"><code>torch==2.3.1</code>: A deep learning framework for flexible research and production, offering tensors and dynamic neural networks in Python with strong GPU acceleration.</li>
                                <li className="link-list"><code>torchvision==0.18.1</code>: Provides datasets, models, and image transformations for computer vision, designed to work with PyTorch.</li>
                            </ul>
                            <li className="link-list"><strong>Natural Language Processing (NLP):</strong></li>
                            <ul>
                                <li className="link-list"><code>nltk==3.8.1</code>: A leading platform for building Python programs to work with human language data, providing easy-to-use interfaces to over 50 corpora and lexical resources, such as WordNet.</li>
                                <li className="link-list"> <code>spacy==3.7.5</code>: An industrial-strength NLP library designed for processing and analyzing large volumes of text, with support for advanced text processing tasks.</li>
                                <li className="link-list"><code>transformers==4.41.2</code>: A library providing general-purpose architectures for NLP, including implementations of state-of-the-art models like BERT, GPT-3, and T5.</li>
                            </ul>
                            <li className="link-list"><strong>Data preprocessing and feature engineering:</strong></li>
                            <ul>
                                <li className="link-list"><code>category_encoders==2.6.3</code>: A collection of sklearn-compatible transformers for encoding categorical variables, enabling better handling of categorical data in machine learning workflows.</li>
                            </ul>
                            <li className="link-list"><strong>Hyperparameter optimization:</strong></li>
                            <ul>
                                <li className="link-list"><code>optuna==3.6.1</code>: A hyperparameter optimization framework that automates the process of hyperparameter tuning, using state-of-the-art algorithms and optimization techniques.</li>
                                <li className="link-list"><code>hyperopt==0.2.7</code>: A distributed asynchronous optimization framework, providing tools for search space definition and optimization algorithms.</li>
                            </ul>
                            <li className="link-list"><strong>Model interpretability:</strong></li>
                            <ul>
                                <li className="link-list"><code>shap==0.45.1</code>: A library for explaining the output of machine learning models, providing insights into model predictions using SHapley Additive exPlanations (SHAP).</li>
                                <li className="link-list"><code>lime==0.2.0.1</code>: A tool for explaining the predictions of any machine learning classifier, providing local interpretable model-agnostic explanations.</li>
                            </ul>
                            <li className="link-list"><strong>Image processing:</strong></li>
                            <ul>
                                <li className="link-list"><code>opencv-python==4.10.0.84</code>: A library of programming functions aimed primarily at real-time computer vision, providing support for image processing and computer vision tasks.</li>
                                <li className="link-list"><code>Pillow==10.3.0</code>: The friendly PIL fork (Python Imaging Library), adding support for opening, manipulating, and saving many different image file formats.</li>
                            </ul>
                            <li className="link-list"><strong>ONNX:</strong></li>
                            <ul>
                                <li className="link-list"><code>onnx==1.16.1</code>: An open standard for representing machine learning models, enabling interoperability between different deep learning frameworks.</li>
                            </ul>
                            <li className="link-list"><strong>XGBoost:</strong></li>
                            <ul>
                                <li className="link-list"><code>xgboost==2.1.0</code>: A scalable and flexible gradient boosting library, designed for speed and performance in machine learning tasks.</li>
                            </ul>
                            <li className="link-list"><strong>Joblib for saving/loading models:</strong></li>
                            <ul>
                                <li className="link-list"><code>joblib==1.4.2</code>: A library for efficient serialization of large Python objects, useful for saving and loading machine learning models.</li>
                            </ul>
                            <li className="link-list"><strong>Integration of pandas and scikit-learn:</strong></li>
                            <ul>
                                <li className="link-list"><code>sklearn-pandas==2.2.0</code>: Seamlessly integrates pandas with scikit-learn, allowing for better data manipulation and machine learning workflows.</li>
                            </ul>
                            <li className="link-list"><strong>Progress bars:</strong></li>
                            <ul>
                                <li className="link-list"><code>tqdm==4.66.4</code>: A fast, extensible progress bar for Python and CLI, providing easy-to-use progress indicators for loops and processes.</li>
                            </ul>
                        </ul>
                    </div>
                    <Navigation previous={'Previous: Review App'} previousNav={'review-app'} next={"Next: Introduction to Models"} nextNav={"introduction-to-models"}/> 

            </div>
        <div className="h-full hidden xl:block xl:w-[33.33%] px-[24px] sticky top-16">
        <p className='font-semibold my-8 '>On this page</p>
        <ul className='border-t-2 py-4'>
          <li className='link-list '><a href="#overview" className='links'>Overview</a></li>
          <li className='link-list '><a href="#pre-installed-dependencies" className='links'>Pre-installed Dependencies</a></li>

        </ul>
      </div>
        </div>
        </>
    );
};

export default AppBaseimage1;
