import React from 'react'
import { Helmet } from "react-helmet-async";

function SEOHelmet({title,description,canonicalUrl}) {
    return (<Helmet prioritizeSeoTags>
        {title && <title>{title}</title>}
        {description && <meta name="description" content={description} />}
        <meta property="og:type" content='website'/>
        {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
    </Helmet>)
}

export default SEOHelmet
