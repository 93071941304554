import React from "react";
import { Link } from "react-router-dom";
import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { ChevronDownIcon } from '@heroicons/react/20/solid'

function Navbar() {      
    return (
        <>
        {/* <nav className="flex sticky top-0 p-4 font-medium flex justify-between" style={{ backgroundColor: '#ffffff' }}>

            <Link className="flex items-center gap-2" to="/">
                <img src='/./media/navbar-logo.png' alt="Vipas.AI Logo" className="w-[45px] h-[30px]" />
                <span className="font-semibold text-[20px]">Vipas.AI Docs</span>
            </Link>
            <div className="flex">
                <div className="rounded-full bg-[#E7ECEF] overflow-hidden flex items-center mr-6 text-[14px]">

                    <button className="w-[73px] h-full hover:bg-[#000000] hover:text-white font-semibold"
                        onClick={() => { window.location.href = "https://vipas.ai/login" }}
                    >
                        Log In
                    </button>

                    <span className="">|</span>

                    <button className="w-[73px] h-full hover:bg-[#000000] hover:text-white font-semibold"
                        onClick={() => { window.location.href = "https://vipas.ai/signup" }}
                    >
                        Sign Up
                    </button>

                </div>
                <Menu as="div" className="relative inline-block text-left">
                    <div className="">
                        <MenuButton className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm actve:ring-1 active:ring-inset active:ring-gray-300 hover:bg-gray-50 flex items-center hover:bg-[#000000]/[10%]">
                            <i className="fa-solid fa-grip-vertical theme-text"></i>
                            <ChevronDownIcon aria-hidden="true" className="-mr-1 h-5 w-5 text-gray-400" />
                        </MenuButton>
                    </div>

                    <MenuItems
                        transition
                        className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-xl bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in border overflow-hidden"
                    >


                        <MenuItem>
                            <span
                                className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 bg-gradient-to-r from-white to-[#e6ddf6]"
                            >
                                Company
                            </span>
                        </MenuItem>
                        <MenuItem>
                            <a
                                href="https://vipas.ai/aboutus"
                                className="hover:bg-company-list block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                            >
                                About Us
                            </a>
                        </MenuItem>
                        <MenuItem>
                            <a
                                href="https://vipas.ai/faqs"
                                className="hover:bg-company-list block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                            >
                                FAQ
                            </a>
                        </MenuItem>
                        <MenuItem>
                            <a
                                href="https://vipas.ai/career"
                                className="hover:bg-company-list block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                            >
                                Careers
                            </a>
                        </MenuItem>
                        <MenuItem>
                            <span
                                className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 bg-gradient-to-r from-white to-[#e2ffe8]"
                            >
                                Support
                            </span>
                        </MenuItem>
                        <MenuItem>
                            <a
                                href="https://vipas.ai/contactus"
                                className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 hover:bg-support-list"
                            >
                                Contact Us
                            </a>
                        </MenuItem>
                        <MenuItem>
                            <a
                                href="#"
                                className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 hover:bg-support-list"
                            >
                                Report an issue
                            </a>
                        </MenuItem>
                        <MenuItem>
                            <a
                                href={`mailto:help@vipas.ai`}
                                className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 hover:bg-support-list"
                            >
                                help@vipas.ai
                            </a>
                        </MenuItem>
                        <MenuItem>
                            <span
                                className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 bg-gradient-to-r from-white to-[#fbdaf5]"
                            >
                                Join Us
                            </span>
                        </MenuItem>
                        <MenuItem>
                            <a
                                href="http://www.youtube.com/@VipasAI" target="_blank"
                                className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 hover:bg-join-list"
                            >
                                <i className="fa-brands fa-youtube mr-2"></i>YouTube
                            </a>
                        </MenuItem>

                        <MenuItem>
                            <a
                                href="https://www.linkedin.com/company/vipasai/" target="_blank"
                                className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 hover:bg-join-list"
                            >
                                <i className="fa-brands fa-linkedin mr-2"></i>LinkedIn
                            </a>
                        </MenuItem>
                        <MenuItem>
                            <a
                                href="https://www.instagram.com/ai.vipas/" target="_blank"
                                className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 hover:bg-join-list"
                            >
                                <i className="fa-brands fa-instagram mr-2"></i>Instagram
                            </a>
                        </MenuItem>
                        <MenuItem>
                            <a
                                href="https://www.facebook.com/people/Vipasai/61560001117446/" target="_blank"
                                className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 hover:bg-join-list"
                            >
                                <i className="fa-brands fa-facebook mr-2"></i>Facebook
                            </a>
                        </MenuItem>
                        <MenuItem>
                            <a
                                href="https://medium.com/@vipas.ai" target="_blank"
                                className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 hover:bg-join-list"
                            >
                                <i className="fa-brands fa-medium mr-2"></i>Medium
                            </a>
                        </MenuItem>


                    </MenuItems>
                </Menu>
            </div>



        </nav> */}
        <Disclosure as="nav" className="sticky top-0 border bg-[#FFFFFF] z-[10]">
      <div className="mx-auto max-w-[1920px] px-2 sm:px-6 lg:px-8">
        <div className="relative flex h-16 items-center justify-between">
          
          <div className="flex flex-1 items-center items-stretch justify-between">
            <div className="flex flex-shrink-0 items-center">
            <Link className="flex items-center gap-2" to="/">
                <img src='/./media/navbar-logo.png' alt="Vipas.AI Logo" className="w-[45px] h-[30px]" />
                <span className="font-semibold text-[20px]">Vipas.AI Docs</span>
            </Link>
            </div>
            <div className="hidden sm:ml-6 sm:block">
            <div className="flex">
                <div className="rounded-full bg-[#E7ECEF] overflow-hidden flex items-center mr-6 text-[14px]">

                    <button className="w-[73px] h-full hover:bg-[#000000] hover:text-white font-semibold"
                        onClick={() => { window.location.href = "https://vipas.ai/login" }}
                    >
                        Log In
                    </button>

                    <span className="">|</span>

                    <button className="w-[73px] h-full hover:bg-[#000000] hover:text-white font-semibold"
                        onClick={() => { window.location.href = "https://vipas.ai/signup" }}
                    >
                        Sign Up
                    </button>

                </div>
                <Menu as="div" className="relative inline-block text-left">
                    <div className="">
                        <MenuButton className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm actve:ring-1 active:ring-inset active:ring-gray-300 hover:bg-gray-50 flex items-center hover:bg-[#000000]/[10%]">
                            <i className="fa-solid fa-grip-vertical theme-text"></i>
                            <ChevronDownIcon aria-hidden="true" className="-mr-1 h-5 w-5 text-gray-400" />
                        </MenuButton>
                    </div>

                    <MenuItems
                        transition
                        className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-xl bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in border overflow-hidden"
                    >


                        <MenuItem>
                            <span
                                className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 bg-gradient-to-r from-white to-[#e6ddf6]"
                            >
                                Company
                            </span>
                        </MenuItem>
                        <MenuItem>
                            <a
                                href="https://vipas.ai/aboutus"
                                className="hover:bg-company-list block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                            >
                                About Us
                            </a>
                        </MenuItem>
                        <MenuItem>
                            <a
                                href="https://vipas.ai/faqs"
                                className="hover:bg-company-list block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                            >
                                FAQ
                            </a>
                        </MenuItem>
                        <MenuItem>
                            <a
                                href="https://vipas.ai/career"
                                className="hover:bg-company-list block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                            >
                                Careers
                            </a>
                        </MenuItem>
                        <MenuItem>
                            <span
                                className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 bg-gradient-to-r from-white to-[#e2ffe8]"
                            >
                                Support
                            </span>
                        </MenuItem>
                        <MenuItem>
                            <a
                                href="https://vipas.ai/contactus"
                                className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 hover:bg-support-list"
                            >
                                Contact Us
                            </a>
                        </MenuItem>
                        <MenuItem>
                            <a
                                href="https://vipas.ai?reportissue=true"
                                className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 hover:bg-support-list"
                            >
                                Report an issue
                            </a>
                        </MenuItem>
                        <MenuItem>
                            <a
                                href={`mailto:help@vipas.ai`}
                                className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 hover:bg-support-list"
                            >
                                help@vipas.ai
                            </a>
                        </MenuItem>
                        <MenuItem>
                            <span
                                className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 bg-gradient-to-r from-white to-[#fbdaf5]"
                            >
                                Join Us
                            </span>
                        </MenuItem>
                        <MenuItem>
        <a
            href="https://discord.com/invite/Yf9Yg5JUfv" target="_blank"
            className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 hover:bg-join-list"
        >
           <i className="fa-brands fa-discord mr-2"></i>Discord
        </a>
    </MenuItem>
                        <MenuItem>
                            <a
                                href="http://www.youtube.com/@VipasAI" target="_blank"
                                className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 hover:bg-join-list"
                            >
                                <i className="fa-brands fa-youtube mr-2"></i>YouTube
                            </a>
                        </MenuItem>

                        <MenuItem>
                            <a
                                href="https://www.linkedin.com/company/vipasai/" target="_blank"
                                className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 hover:bg-join-list"
                            >
                                <i className="fa-brands fa-linkedin mr-2"></i>LinkedIn
                            </a>
                        </MenuItem>
                        <MenuItem>
                            <a
                                href="https://www.instagram.com/_vipas.ai?igsh=MWl1aTBlOGgxb2t4dw==" target="_blank"
                                className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 hover:bg-join-list"
                            >
                                <i className="fa-brands fa-instagram mr-2"></i>Instagram
                            </a>
                        </MenuItem>
                        <MenuItem>
                            <a
                                href="https://www.facebook.com/people/Vipasai/61560001117446/" target="_blank"
                                className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 hover:bg-join-list"
                            >
                                <i className="fa-brands fa-facebook mr-2"></i>Facebook
                            </a>
                        </MenuItem>
                        <MenuItem>
                            <a
                                href="https://medium.com/@vipas.ai" target="_blank"
                                className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 hover:bg-join-list"
                            >
                                <i className="fa-brands fa-medium mr-2"></i>Medium
                            </a>
                        </MenuItem>


                    </MenuItems>
                </Menu>
            </div>

            </div>
          </div>
          <div className=" inset-y-0 left-0 flex items-center sm:hidden">
            {/* Mobile menu button*/}
            <DisclosureButton className="group relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
              <span className="absolute -inset-0.5" />
              <span className="sr-only">Open main menu</span>
              <Bars3Icon aria-hidden="true" className="block h-6 w-6 group-data-[open]:hidden" />
              <XMarkIcon aria-hidden="true" className="hidden h-6 w-6 group-data-[open]:block" />
            </DisclosureButton>
          </div>
          
        </div>
      </div>

      <DisclosurePanel className="sm:hidden flex flex-col items-center bg-[#FFFFFF] z-[10] sticky top-0">
    
      <div className="flex rounded-full bg-[#E7ECEF] overflow-hidden items-center mr-6 text-[14px] my-4 p-3">

<button className="w-[73px] h-full hover:bg-[#000000] hover:text-white font-semibold"
    onClick={() => { window.location.href = "https://vipas.ai/login" }}
>
    Log In
</button>

<span className="">|</span>

<button className="w-[73px] h-full hover:bg-[#000000] hover:text-white font-semibold"
    onClick={() => { window.location.href = "https://vipas.ai/signup" }}
>
    Sign Up
</button>

</div>
<Menu as="div" className="relative inline-block text-left">
<div className="">
    <MenuButton className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm actve:ring-1 active:ring-inset active:ring-gray-300 hover:bg-gray-50 flex items-center">
        <i className="fa-solid fa-grip-vertical theme-text"></i>
        <ChevronDownIcon aria-hidden="true" className="-mr-1 h-5 w-5 text-gray-400" />
    </MenuButton>
</div>

<MenuItems
    transition
    className="mb-4 z-10 mt-2 w-56 origin-top-right rounded-xl bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in  overflow-hidden "
>


    <MenuItem>
        <span
            className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 bg-gradient-to-r from-white to-[#e6ddf6]"
        >
            Company
        </span>
    </MenuItem>
    <MenuItem>
        <a
            href="https://vipas.ai/aboutus"
            className="hover:bg-company-list block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
        >
            About Us
        </a>
    </MenuItem>
    <MenuItem>
        <a
            href="https://vipas.ai/faqs"
            className="hover:bg-company-list block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
        >
            FAQ
        </a>
    </MenuItem>
    <MenuItem>
        <a
            href="https://vipas.ai/career"
            className="hover:bg-company-list block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
        >
            Careers
        </a>
    </MenuItem>
    <MenuItem>
        <span
            className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 bg-gradient-to-r from-white to-[#e2ffe8]"
        >
            Support
        </span>
    </MenuItem>
    <MenuItem>
        <a
            href="https://vipas.ai/contactus"
            className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 hover:bg-support-list"
        >
            Contact Us
        </a>
    </MenuItem>
    <MenuItem>
        <a
            href="https://vipas.ai?reportissue=true"
            className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 hover:bg-support-list"
        >
            Report an issue
        </a>
    </MenuItem>
    <MenuItem>
        <a
            href={`mailto:help@vipas.ai`}
            className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 hover:bg-support-list"
        >
            help@vipas.ai
        </a>
    </MenuItem>
    <MenuItem>
        <span
            className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 bg-gradient-to-r from-white to-[#fbdaf5]"
        >
            Join Us
        </span>
    </MenuItem>
    <MenuItem>
        <a
            href="https://discord.com/invite/Yf9Yg5JUfv" target="_blank"
            className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 hover:bg-join-list"
        >
           <i className="fa-brands fa-discord mr-2"></i>Discord
        </a>
    </MenuItem>
    <MenuItem>
        <a
            href="http://www.youtube.com/@VipasAI" target="_blank"
            className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 hover:bg-join-list"
        >
            <i className="fa-brands fa-youtube mr-2"></i>YouTube
        </a>
    </MenuItem>

    <MenuItem>
        <a
            href="https://www.linkedin.com/company/vipasai/" target="_blank"
            className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 hover:bg-join-list"
        >
            <i className="fa-brands fa-linkedin mr-2"></i>LinkedIn
        </a>
    </MenuItem>
    <MenuItem>
        <a
            href="https://www.instagram.com/_vipas.ai?igsh=MWl1aTBlOGgxb2t4dw==" target="_blank"
            className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 hover:bg-join-list"
        >
            <i className="fa-brands fa-instagram mr-2"></i>Instagram
        </a>
    </MenuItem>
    <MenuItem>
        <a
            href="https://www.facebook.com/people/Vipasai/61560001117446/" target="_blank"
            className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 hover:bg-join-list"
        >
            <i className="fa-brands fa-facebook mr-2"></i>Facebook
        </a>
    </MenuItem>
    <MenuItem>
        <a
            href="https://medium.com/@vipas.ai" target="_blank"
            className="block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 hover:bg-join-list"
        >
            <i className="fa-brands fa-medium mr-2"></i>Medium
        </a>
    </MenuItem>


</MenuItems>
</Menu>
      </DisclosurePanel>
    </Disclosure>





</>
    );
}

export default Navbar;
